<template>
    <div
        class="page page-index"
        :class="[
            {
                'third-delivery': pageType == 1,
            },
        ]"
    >
        <div class="head" :class="{ 'fixed-search': isFixedSearch }" @touchmove.prevent>
            <div class="con" ref="con">
                <div class="title">
                    {{ pageType == 1 ? '美团配送，下单成功后不支持退款' : '自助餐厅，不支持外卖' }}
                </div>
                <div class="step-list">
                    <div class="step-item select">
                        <img class="img" src="@/assets/icons/icon-pay-step-0102.png" />
                        <div class="value">选择餐厅</div>
                    </div>
                    <div class="step-item">
                        <img class="img" src="@/assets/icons/icon-pay-step-0201.png" />
                        <div class="value">{{ pageType == 2 ? '确定订单' : '选择菜品' }}</div>
                    </div>
                    <div class="step-item">
                        <img class="img" src="@/assets/icons/icon-pay-step-0301.png" />
                        <div class="value">{{ pageType == 1 ? '门店出餐' : '等待取餐码' }}</div>
                    </div>
                    <div class="step-item">
                        <img class="img" src="@/assets/icons/icon-pay-step-0401.png" />
                        <div class="value">{{ pageType == 1 ? '配送到家' : '到店取餐' }}</div>
                    </div>
                </div>
            </div>
            <div class="top">
                <div class="content">
                    <div class="left" @click="toSetCity">
                        <div class="text">{{ initCity.city.name }}</div>
                        <img class="arrow" src="@/assets/icons/icon-down.png" />
                    </div>
                    <div class="right">
                        <input
                            type="text"
                            class="right-input"
                            v-model="kw"
                            placeholder="请输入关键字搜索"
                        />
                    </div>

                    <div class="close-btn" v-if="kw" @click="clearKw">
                        <img class="close-img" src="@/assets/img/clear_icon.png" />
                    </div>
                </div>
            </div>
            <div class="tab">
                <div class="box" :class="{ select: current == 0 }" @click="changeSelect(0)">
                    附近餐厅
                    <div class="num">{{ restaurantNum.nearby }}</div>
                </div>
                <div class="box" :class="{ select: current == 1 }" @click="changeSelect(1)">
                    收藏的餐厅
                    <div class="num">{{ restaurantNum.favorite }}</div>
                </div>
            </div>
        </div>
        <div class="con-list">
            <div
                class="list"
                v-for="(item, index) in queryData"
                :class="{ 'not-open': item.displayStatus != 1 }"
                :key="index"
                v-if="queryData.length > 0"
            >
                <div class="left" @click="toMenu(index)">
                    <div class="name">
                        <div class="text">{{ item.storeName }}</div>
                        <div class="status"></div>
                    </div>
                    <div class="addr">{{ item.address }}</div>
                    <div class="num" v-if="showDistance">{{ item.distance }}</div>
                </div>
                <div class="right" @click="changeLove(index)">
                    <img
                        class="love"
                        src="@/assets/icons/icon-start-02.png"
                        v-if="item.isFavorite"
                    />
                    <img
                        class="love"
                        src="@/assets/icons/icon-start-01.png"
                        v-if="!item.isFavorite"
                    />
                </div>
            </div>
            <div v-if="queryData.length < 1" class="none-length">暂无搜索结果！</div>
        </div>
        <!-- 分享 -->
        <div class="customer-service-btn" @click="toCs">
            <img src="@/assets/icons/icon-customer-service.png" class="img" />
            <div class="value">客服</div>
        </div>
        <div class="order-list-btn" @click="toOrderList">
            <img src="@/assets/icons/order-list.png" class="img" />
            <div class="value">订单</div>
        </div>
        <Scroll :onBottom="onBottom" :lowerThreshold="600" />
    </div>
</template>
<script>
import {
    GET_LARTELY_STOP,
    GET_BANNER_OR_ICON,
    GET_LOVE_STOP,
    GET_STOP_NUM,
    SET_LOVE_STOP,
    GET_STOP_INFO,
    EXCHANGE_CARD,
    GET_USER_AVAILABLE_CARD_LIST,
    GET_USER_ADDRESS_STORE,
    GET_ORDER_NO_BY_AGISO,
} from 'api';
import {
    PAGE_RESTAURANT_LIST,
    PAGE_STOP_MENU,
    PAGE_ORDER_CREATE_BY_AGISO,
    PAGE_ORDER_LIST,
    PAGE_SET_CITY,
    PAGE_CUSTOMER_SERVICE,
    PAGE_ORDER_INFO,
} from 'page';
import Share from '@/components/share';
import CustomerService from '@/components/customerService/btn.vue';
import Scroll from '@/components/scroll';
import Swiper from '@/lib/swiper3.4.2/swiper.min.js';
import '@/lib/swiper3.4.2/swiper.min.css';
export default {
    data() {
        return {
            pageType: 0,
            // 0 堂食订单
            // 1 外卖订单
            // 2 阿奇索订单
            addressId: 0,
            isIniting: false,
            pageIndex: 1,
            pageSize: 20,
            pageLocation: {},
            queryData: [],
            imgUrls: [],
            bannerSwiper: null,
            kw: '',
            current: 0,
            stopLength: 2,
            restaurantNum: {
                favorite: 0,
                nearby: 0,
            },
            // con 高度
            conHeight: 0,
            isFixedSearch: false,
            waitExchangeCardList: [],
        };
    },
    computed: {
        cityId() {
            return this.$store.getters.getCurrentLocation.city.id;
        },
        userId() {
            return this.$store.state.user.userInfo.id || null;
        },
        initCity() {
            return this.$store.getters.getCurrentLocation;
        },
        showDistance() {
            if (this.addressId) {
                return true;
            } else if (this.$store.state.location.currentLocation.lon) {
                return true;
            } else {
                return false;
            }
        },
        isHasLocation() {
            return this.$store.state.location.isHasLocation;
        },
    },
    watch: {
        initCity(val) {
            this.isIniting = false;
            this.init(1);
        },
        userId(val) {
            if (val && this.queryData.length == 0) {
                this.init();
            }
        },
        kw(val) {
            this.getData(1);
            this.getNum();
        },
    },
    components: {
        Share,
        CustomerService,
        Scroll,
    },
    created() {
        this.pageType = this.$route.query.type || 0;
        this.addressId = this.$route.query.addressId || 0;
        if (this.cityId) {
            this.init();
        }
    },
    mounted() {
        window.addEventListener('scroll', this.scrollToTop);
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (
                vm.$store.state.location.currentLocation.city.id &&
                vm.$store.state.location.currentLocation.city.id !=
                    vm.$store.state.location.selectLocation.city.id &&
                !vm.$store.state.location.isShowFirst &&
                vm.$store.state.location.selectLocation.city.id &&
                vm.$store.state.location.isShowFirst
            ) {
                let message = `检测您当前在 <span style="font-weight:bold;">${vm.$store.state.location.currentLocation.city.name}</span> ，是否切换？`;
                if (vm.$store.state.common.env != 'PRO') {
                    message = `检测您当前在 <span style="font-weight:bold;">${vm.$store.state.location.currentLocation.city.name} [${vm.$store.state.location.currentLocation.city.id}-${vm.$store.state.location.selectLocation.city.id}-${vm.$store.state.location.isShowFirst}]</span> ，是否切换？`;
                }
                // TODO
                vm.$confirm.open({
                    title: '温馨提示',
                    message,
                    confirmText: '切换',
                    success: res => {
                        if (res.success) {
                            vm.$store.commit(
                                'setSelectLocation',
                                vm.$store.state.location.currentLocation
                            );
                        }
                    },
                });
            }
            vm.$store.commit('setShowFirst', true);
        });
    },
    methods: {
        // init
        init(type = 0) {
            if (this.isIniting) {
                return;
            }
            this.isIniting = true;
            if (this.$store.state.location.currentLocation.city.id) {
                this.pageLocation = this.$store.state.location.currentLocation;
            } else {
                this.pageLocation = this.$store.state.location.selectLocation;
            }
            if (!this.cityId) {
                this.isIniting = false;
                return;
            }
            this.waitExchangeCardList = JSON.parse(this.$route.query.codes || '[]');
            this.exchangeCard();
            this.getData(type);
            if (this.pageType == 0 || this.pageType == 2) {
                this.getNum();
            }
        },
        // 兑换优惠券
        async exchangeCard() {
            if (this.waitExchangeCardList.length == 0) return;

            this.$store.commit('setWaitUseCardList', this.waitExchangeCardList);
            let cardListRes = await this.$util.get(GET_USER_AVAILABLE_CARD_LIST);
            let cardList = [];

            if (cardListRes.success) {
                cardList = cardListRes.data.map(v => v.code);
                for (let i = 0; i < this.waitExchangeCardList.length; i++) {
                    const item = this.waitExchangeCardList[i];
                    if (cardList.indexOf(item) == -1) {
                        this.$util.post(EXCHANGE_CARD, {
                            code: item,
                        });
                    }
                }
            }
        },
        scrollToTop() {
            if (!this.conHeight) {
                if (this.$refs.con) {
                    this.conHeight = this.$refs.con.offsetHeight;
                } else {
                    return;
                }
            }

            var scrollTop =
                window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

            if (scrollTop > this.conHeight) {
                this.isFixedSearch = true;
            } else {
                this.isFixedSearch = false;
            }
        },
        // 收藏餐厅
        changeLove(index) {
            this.queryData[index].isFavorite = !this.queryData[index].isFavorite;
            if (this.queryData[index].isFavorite) {
                this.restaurantNum.favorite++;
            } else {
                this.restaurantNum.favorite--;
            }
            this.$util
                .post(SET_LOVE_STOP, {
                    storeCode: this.queryData[index].storeCode,
                    favorite: this.queryData[index].isFavorite,
                })
                .then(res => {
                    if (!res.success) {
                        if (!this.queryData[index].isFavorite) {
                            this.restaurantNum.favorite++;
                        } else {
                            this.restaurantNum.favorite--;
                        }
                        this.queryData[index].isFavorite = !this.queryData[index].isFavorite;
                    }
                });
        },
        // 切换餐厅
        changeSelect(index) {
            if (this.current == index) {
                return;
            }
            document.getElementsByClassName('head')[0].scrollIntoView();
            this.current = index;
            this.getData(1);
        },
        // 去餐厅商品
        toMenu(index) {
            if (this.queryData[index].displayStatus != 1) {
                return this.$toast('门店未营业');
            }
            let pageData = {};
            let pageUrl = '';
            if (this.pageType == 0) {
                pageUrl = PAGE_STOP_MENU;
                pageData = {
                    storeCode: this.queryData[index].storeCode,
                    storeName: this.queryData[index].storeName,
                    address: this.queryData[index].address,
                    productIds: this.$route.query.productIds,
                };
            } else if (this.pageType == 1) {
                pageUrl = PAGE_STOP_MENU;
                pageData = {
                    storeCode: this.queryData[index].storeCode,
                    storeName: this.queryData[index].storeName,
                    address: this.queryData[index].address,
                    productIds: this.$route.query.productIds,
                    addressId: this.addressId,
                };
            } else if (this.pageType == 2) {
                pageUrl = PAGE_ORDER_CREATE_BY_AGISO;
                pageData = {
                    storeCode: this.queryData[index].storeCode,
                    storeName: this.queryData[index].storeName,
                    agisoIdleOrderNo: this.$route.query.agisoIdleOrderNo,
                };
            }
            this.$util.pageJump(pageUrl, pageData);
        },
        // 选择城市
        toSetCity() {
            this.$util.pageJump(PAGE_SET_CITY);
        },
        // 获取餐厅数量
        getNum() {
            this.$util
                .post(GET_STOP_NUM, {
                    areaCityId: this.initCity.city.id,
                    kw: this.kw,
                })
                .then(res => {
                    if (res.success) {
                        this.restaurantNum = res.data;
                    }
                });
        },
        // 获取餐厅
        getData(type = 0) {
            let url = '';
            let requestData = {};
            let requestType = '';

            if (type == 1) {
                this.pageIndex = 1;
            }

            if (this.pageType == 0) {
                url = this.current == 0 ? GET_LARTELY_STOP : GET_LOVE_STOP;
                requestData = {
                    areaCityId: this.initCity.city.id,
                    lon: this.initCity.lon,
                    lat: this.initCity.lat,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    kw: this.kw,
                };
                requestType = 'post';
            } else if (this.pageType == 1) {
                url = GET_USER_ADDRESS_STORE;
                requestData = {
                    addressId: this.addressId,
                };
                requestType = 'get';
            } else if (this.pageType == 2) {
                url = this.current == 0 ? GET_LARTELY_STOP : GET_LOVE_STOP;
                requestData = {
                    areaCityId: this.initCity.city.id,
                    lon: this.initCity.lon,
                    lat: this.initCity.lat,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    kw: this.kw,
                };
                requestType = 'post';
                this.getOrderNoByAgiso();
            }

            this.$util[requestType](url, requestData).then(res => {
                if (res.success) {
                    if (res.data.length < this.pageSize) {
                        this.isBottom = true;
                    } else {
                        this.isBottom = false;
                    }
                    if (type == 1) {
                        this.queryData = [];
                    }
                    if (this.kw) {
                        document.getElementsByClassName('head')[0].scrollIntoView();
                    }
                    this.queryData = this.$util.distinct(
                        [...this.queryData, ...res.data],
                        'storeCode'
                    );
                }
            });
        },
        getOrderNoByAgiso() {
            this.$util
                .get(GET_ORDER_NO_BY_AGISO, {
                    agisoIdleOrderNo: this.$route.query.agisoIdleOrderNo,
                })
                .then(res => {
                    if (res.success) {
                        if (res.data.qzOrderNo) {
                            this.$util.pageJump({
                                path: PAGE_ORDER_INFO,
                                query: {
                                    orderNo: res.data.qzOrderNo,
                                },
                                config: {
                                    jumpType: 1,
                                },
                            });
                        }
                    }
                });
        },
        // 清除查询内容
        clearKw() {
            this.kw = '';
        },
        // 触底事件
        onBottom() {
            if (this.isBottom) {
                return;
            }
            this.pageIndex++;
            this.getData();
        },
        toCs() {
            this.$util.pageJump(PAGE_CUSTOMER_SERVICE);
        },
        toOrderList() {
            this.$util.pageJump(PAGE_ORDER_LIST);
        },
    },
};
</script>

<style lang="less" scoped>
@import url('~@/lib/base.less');
.head {
    height: .pxToRem(428) [];
    &.fixed-search {
        > .top {
            position: fixed;
            top: 0;
            z-index: 100;
        }
        > .tab {
            position: fixed;
            top: .pxToRem(98) [];
            z-index: 100;
        }
    }
}
.con {
    width: 100%;
    height: .pxToRem(238) [];
    border-radius: 0 0 .pxToRem(40) [] .pxToRem(40) [];
    background: white;
    overflow: hidden;
    .title {
        .base-center-x;
        width: 100%;
        text-align: center;
        top: .pxToRem(20) [];
        height: .pxToRem(56) [];
        line-height: .pxToRem(56) [];
        font-size: .pxToRem(34) [];
        color: rgba(0, 0, 0, 0.9);
    }
    .step-list {
        position: absolute;
        top: .pxToRem(96) [];
        left: .pxToRem(68) [];
        right: .pxToRem(68) [];
        height: .pxToRem(104) [];
        .jc-sb;
        .step-item {
            width: .pxToRem(88) [];
            .img {
                .base-center-x;
                top: 0;
                .sq(56);
            }
            .value {
                .base-center-x;
                bottom: 0;
                height: .pxToRem(32) [];
                line-height: .pxToRem(32) [];
                font-size: .pxToRem(22) [];
                white-space: nowrap;
                color: rgba(0, 0, 0, 0.5);
            }
            &.select {
                .value {
                    color: rgba(0, 0, 0, 0.9);
                }
            }
            &::after {
                content: ' ';
                position: absolute;
                top: .pxToRem(23) [];
                right: .pxToRem(-85) [];
                width: .pxToRem(82) [];
                height: .pxToRem(12) [];
                background: url('~@/assets/icons/icon-arrow.png');
                background-size: cover;
            }
            &:last-child {
                &::after {
                    content: '';
                    display: none;
                }
            }
        }
    }
}
.top {
    background: #f0f0f0;
    border-top: .pxToRem(30) [] solid #f0f0f0;
    padding: 0 .pxToRem(20) [];
    .content {
        position: relative;
        padding: 0 .pxToRem(34) [];
        margin: 0 auto;
        width: .pxToRem(710) [];
        height: .pxToRem(68) [];
        line-height: .pxToRem(68) [];
        background-color: #ffffff;
        border-radius: .pxToRem(34) [];
        display: flex;
        justify-content: flex-start;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        .left {
            min-width: .pxToRem(122) [];
            display: flex;
            .text {
                font-size: .pxToRem(28) [];
                max-width: .pxToRem(112) [];
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.9);
            }
            .arrow {
                .sq(24);
                margin-left: .pxToRem(16) [];
                margin-right: .pxToRem(16) [];
                margin-top: .pxToRem(20) [];
            }
        }
        .right {
            flex: 1;
            height: .pxToRem(68) [];
            .right-input {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                height: .pxToRem(68) [];
                line-height: .pxToRem(68) [];
                font-size: .pxToRem(26) [];
                background: none;
            }
        }
    }
}
.no-open-location {
    margin-top: .pxToRem(16) [];
    height: .pxToRem(50) [];
    line-height: .pxToRem(50) [];
    background: #fd1f5d;
    color: white;
    font-size: .pxToRem(22) [];
    box-sizing: border-box;
    padding-left: .pxToRem(60) [];
    img {
        position: absolute;
        width: .pxToRem(22) [];
        height: .pxToRem(26) [];
        left: .pxToRem(24) [];
        top: 50%;
        transform: translateY(-50%);
    }
}
.close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: .pxToRem(67) [];
    height: .pxToRem(67) [];
}

.close-btn .close-img {
    position: absolute;
    top: .pxToRem(20) [];
    right: .pxToRem(20) [];
    width: .pxToRem(32) [];
    height: .pxToRem(32) [];
}

.con-list {
    width: 100%;
    .none-length {
        width: 100%;
        height: .pxToRem(400) [];
        line-height: .pxToRem(400) [];
        text-align: center;
    }
    .list {
        .jc-sb;
        width: .pxToRem(710) [];
        margin: 0 auto;
        margin-bottom: .pxToRem(10) [];
        background: white;
        box-shadow: .pxToRem(3) [] .pxToRem(3) [] .pxToRem(8) [] 0px rgba(0, 0, 0, 0.1);
        border-radius: .pxToRem(30) [];
        .left {
            width: .pxToRem(620) [];
            box-sizing: border-box;
            padding-left: .pxToRem(30) [];
            .name {
                display: flex;
                justify-content: flex-start;
                font-size: .pxToRem(30) [];
                font-weight: 600;
                height: .pxToRem(40) [];
                line-height: .pxToRem(40) [];
                margin-top: .pxToRem(20) [];
                align-items: center;
                .text {
                    max-width: .pxToRem(480) [];
                    .overflow(1);
                    margin-right: .pxToRem(12) [];
                }
                .status {
                    width: .pxToRem(80) [];
                    height: .pxToRem(32) [];
                    background: url('~@/assets/icons/icon-display-status-1.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
            .addr {
                font-size: .pxToRem(24) [];
                color: rgba(0, 0, 0, 0.5);
                line-height: .pxToRem(36) [];
                margin-top: .pxToRem(6) [];
                margin-bottom: .pxToRem(8) [];
            }
            .num {
                margin-top: .pxToRem(10) [];
                margin-bottom: .pxToRem(18) [];
                font-size: .pxToRem(24) [];
                color: rgba(0, 0, 0, 0.9);
                height: .pxToRem(36) [];
                line-height: .pxToRem(36) [];
                text-indent: .pxToRem(42) [];
                &:after {
                    content: ' ';
                    .base-center-y;
                    left: 0;
                    .sq(24);
                    background: url('~@/assets/icons/icon-location-map.png');
                    background-size: cover;
                }
            }
        }
        .right {
            text-align: right;
            position: relative;
            .love {
                position: absolute;
                top: .pxToRem(24) [];
                right: .pxToRem(40) [];
                .sq(32);
            }
        }
        &.not-open {
            filter: grayscale(100%);
            .left {
                .name {
                    font-weight: 400;
                    .text {
                        color: rgba(0, 0, 0, 0.5);
                        display: flex;
                        align-items: center;
                    }
                    .status {
                        background: url('~@/assets/icons/icon-display-status-0.png');
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }
                .num {
                    color: rgba(0, 0, 0, 0.5);
                }
            }
        }
    }
}

.tab {
    width: 100%;
    height: .pxToRem(84) [];
    line-height: .pxToRem(84) [];
    .jc-sb;
    position: relative;
    background: #f0f0f0;
    border-top: .pxToRem(8) [] solid #f0f0f0;
    .box {
        display: flex;
        justify-content: center;
        flex: 1;
        line-height: .pxToRem(84) [];
        white-space: nowrap;
        font-size: .pxToRem(30) [];
        font-weight: 600;
        align-items: center;
        .num {
            display: inline-block;
            height: .pxToRem(32) [];
            line-height: .pxToRem(32) [];
            font-size: .pxToRem(20) [];
            margin-left: .pxToRem(10) [];
            padding: 0 .pxToRem(6) [];
            background: #000000;
            color: white;
            min-width: .pxToRem(48) [];
            text-align: center;
            border-radius: .pxToRem(16) [];
            box-sizing: border-box;
        }

        &.select {
            color: @theme_color_first;
            .num {
                background: @theme_color_first;
            }
        }
    }
}

/* 客服 */
.customer-service-btn {
    position: fixed;
    bottom: .pxToRem(216) [];
    right: .pxToRem(20) [];
    .sq(96);
    border-radius: 100%;
    background: white;
    box-shadow: .pxToRem(3) [] .pxToRem(8) [] .pxToRem(15) [] 0px rgba(0, 0, 0, 0.3);
    .img {
        .base-center-x;
        top: .pxToRem(16) [];
        .sq(40);
    }
    .value {
        .base-center-x;
        bottom: .pxToRem(10) [];
        height: .pxToRem(28) [];
        line-height: .pxToRem(28) [];
        font-size: .pxToRem(20) [];
        color: @theme_color_first;
    }
}
.order-list-btn {
    position: fixed;
    bottom: .pxToRem(326) [];
    right: .pxToRem(20) [];
    .sq(96);
    border-radius: 100%;
    background: @theme_color_first;
    box-shadow: .pxToRem(3) [] .pxToRem(8) [] .pxToRem(15) [] 0px rgba(0, 0, 0, 0.3);
    .img {
        .base-center-x;
        top: .pxToRem(16) [];
        .sq(40);
    }
    .value {
        .base-center-x;
        bottom: .pxToRem(10) [];
        height: .pxToRem(28) [];
        line-height: .pxToRem(28) [];
        font-size: .pxToRem(20) [];
        color: white;
    }
}
.third-delivery {
    .head {
        height: .pxToRem(258) [];
    }
    .tab,
    .top {
        display: none;
    }
}
</style>
